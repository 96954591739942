import "./assets/css/bootstrap.min.css";
import "./assets/css/style.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import AppContainer from "./containers/AppContainer";

function App() {
  const queryClient = new QueryClient();
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <AppContainer />
      </QueryClientProvider>
    </>
  );
}

export default App;
