import React from "react";
import Step1 from "../assets/imgs/step-1.webp";
import Step2Blur from "../assets/imgs/step-2-blur.webp";
import Logo from "../assets/imgs/logo.png";
import Glass from "../assets/imgs/glass-pic.webp";
import "../App.css";

const LandingPage = ({ reset, setStep, recipeQuery, setRecipeQuery }) => {
  const [howToClicked, setHowToClicked] = React.useState(false);

  return (
    <>
      <div className="container">
        <div
          onClick={() => {
            reset();
          }}
          className="text-center"
        >
          <img
            className="mt-3 mb-3"
            src={Logo}
            alt="logo"
            width={70}
            height={134}
          />
        </div>
        <div className="bg-white d-flex justify-content-between align-items-center p-2 rounded-2">
          <div className="">
            <p className="mb-0">
              Generate a cocktail recipe that{" "}
              <span style={{ color: "red" }}>...</span>
            </p>
          </div>
          <div>
            <img src={Glass} height="46" width="48" alt="" />
          </div>
        </div>
        <div className="mt-3">
          <input
            type="text"
            value={recipeQuery}
            autoFocus
            onChange={(e) => {
              setRecipeQuery(e.target.value);
            }}
            className="form-control mb-1 border-0 mainPlaceholder"
            style={{ height: "140px" }}
            placeholder="... is strong and fruity"
          />
          {/* <textarea
            className="form-control mb-1 border-0 "
            rows="5"
            placeholder="| e.g. walking through Paris in June"
          ></textarea> */}
        </div>
        {howToClicked ? (
          <div className="text-center mt-2">
            <p className="mb-0">
              <span className="semibold"> Type for example:</span> ..reminds me
              of a summer night in Berlin-Charlottenburg.
            </p>
          </div>
        ) : null}
        <div className="text-center mt-3">
          <button
            className="btn hover-btn me-2"
            onClick={() => {
              setStep(1);
            }}
          >
            Continue
          </button>
          <button
            className="btn non-hover-btn"
            onClick={() => {
              setHowToClicked(true);
            }}
          >
            how to?
          </button>
        </div>
        <footer>
          <div className="mt-5 text-center">
            <div className="d-flex justify-content-center ">
              <button className="btn border-0 p-0">
                <img src={Step1} alt="" width="41px" height="44px" />
              </button>
              <div className="border-line"></div>
              <button className="btn border-0 p-0">
                <img src={Step2Blur} alt="" width="41px" height="44px" />
              </button>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default LandingPage;
