import React from "react";
import Logo from "../assets/imgs/logo.png";
import Glass from "../assets/imgs/glass-pic.webp";
import Step1 from "../assets/imgs/step-1.webp";
import Step2 from "../assets/imgs/step-2.png";
import Spinner from "./spinner/spinner";

const YourName = ({
  reset,
  recipeQuery,
  name,
  setName,
  queryRecipe,
  isLoading,
}) => {
  return (
    <>
      <div className="container">
        <div
          className="text-center"
          onClick={() => {
            reset();
          }}
        >
          <img
            className="mt-3 mb-3"
            src={Logo}
            alt=""
            width={70}
            height={134}
          />
        </div>
        <div className="bg-white d-flex justify-content-between align-items-center p-2 rounded-2">
          <div className="">
            <p className="mb-0">
              Generate a cocktail recipe that{" "}
              <span style={{ color: "red" }}>...</span>
            </p>
          </div>
          <div className="">
            <img src={Glass} height="46" width="48" alt="" />
          </div>
        </div>
        <div className="mt-3">
          <textarea
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
            autoFocus
            className="form-control mb-1 border-0 "
            rows="5"
            placeholder="Enter Your Name"
          ></textarea>
        </div>
        <div className="text-center mt-3">
          <button
            className="btn hover-btn me-2"
            onClick={() => {
              queryRecipe(recipeQuery);
            }}
          >
            {isLoading ? <Spinner /> : "CREATE DRINK"}
          </button>
        </div>
        <footer>
          <div className="mt-5 text-center">
            <div className="d-flex justify-content-center ">
              <button className="btn border-0 p-0">
                <img src={Step1} alt="" width="41px" height="44px" />
              </button>
              <div className="border-line"></div>
              <button className="btn border-0 p-0">
                <img src={Step2} alt="" width="41px" height="44px" />
              </button>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default YourName;
