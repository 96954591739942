import { getRecipe } from "../../services";
import { useMutation } from "@tanstack/react-query";
export const useRecipe = (handleSuccess) => {
  return useMutation(
    (query) => {
      return getRecipe(
        `generate a cocktail recipe that tastes like ${query}, provide me response in html formate`
      );
    },
    {
      onSuccess: (data) => {
        if (data.statusCode === 200) {
          handleSuccess(data.data);
        }
      },
    }
  );
};
