import React, { useState } from "react";
import LandingPage from "../../components/LandingPage";
// import Recipe from "../../components/Recipe";
import ShareRecipe from "../../components/ShareRecipe";
import YourName from "../../components/YourName";
import { useRecipe } from "../../hooks/common";

const AppContainer = () => {
  const [step, setStep] = React.useState(0);
  const [name, setName] = React.useState("");
  const [recipeResponse, setRecipeResponse] = React.useState(null);
  const [recipeQuery, setRecipeQuery] = React.useState("");
  const [recipeFetched, setRecipeFetched] = React.useState(false);
  const [isSharing, setIsSharing] = React.useState(false);
  const [enableQuery, setEnableQuery] = React.useState(false);

  const handleSuccess = (recipe) => {
    setStep(3);
    setRecipeResponse(recipe);
    setRecipeFetched(true);
  };

  const handleReset = () => {
    setStep(0);
    setRecipeResponse(null);
    setRecipeQuery("");
    setName("");
    setRecipeFetched(false);
  };

  const { mutate: queryRecipe, isLoading } = useRecipe(handleSuccess);

  return (
    <>
      {step === 0 ? (
        <LandingPage
          setStep={setStep}
          recipeQuery={recipeQuery}
          setRecipeQuery={setRecipeQuery}
          reset={handleReset}
        />
      ) : null}
      {step === 1 ? (
        <YourName
          isLoading={isLoading}
          name={name}
          recipeQuery={recipeQuery}
          setName={setName}
          setEnableQuery={setEnableQuery}
          queryRecipe={queryRecipe}
          reset={handleReset}
        />
      ) : null}
      {recipeFetched && recipeResponse ? (
        <ShareRecipe name={name} recipe={recipeResponse} reset={handleReset} />
      ) : null}
    </>
  );
};

export default AppContainer;
