import React, { useState } from "react";
import html2canvas from "html2canvas";
import Logo from "../assets/imgs/logo.png";
import Refresh from "../assets/imgs/refresh.webp";
import Share from "../assets/imgs/share.webp";

const ShareRecipe = ({ name, recipe, reset }) => {
  const [screenshot, setScreenShot] = useState(null);

  // const dataURItoBlob = (dataURI) => {
  //   let byteString = atob(dataURI.split(",")[1]);
  //   let ab = new ArrayBuffer(byteString.length);
  //   let ia = new Uint8Array(ab);
  //   for (let i = 0; i < byteString.length; i++) {
  //     ia[i] = byteString.charCodeAt(i);
  //   }
  //   return new Blob([ia], {
  //     type: "image/jpeg",
  //   });
  // };
  // const upload = async (response) => {
  //   let blob = dataURItoBlob(dataURL);
  //   let formData = new FormData();
  //   formData.append("access_token", response.authResponse.accessToken);
  //   formData.append("source", blob);

  //   let responseFB = await fetch(`https://graph.facebook.com/me/photos`, {
  //     body: formData,
  //     method: "post",
  //   });
  //   responseFB = await responseFB.json();
  //   console.log(responseFB);
  // };
  // document.getElementById("upload").addEventListener("click", () => {
  //   FB.login(
  //     (response) => {
  //       //TODO check if user is logged in and authorized publish_actions
  //       upload(response);
  //     },
  //     { scope: "publish_actions" }
  //   );
  // });
  // const handleShare = () => {
  //   html2canvas(document.getElementById("recipe")).then((canvas) => {
  //     const dataUrl = canvas.toDataURL("image/jpeg");
  //   });
  // };

  return (
    <>
      <div id="recipe" className="container">
        <div className="text-center">
          <img
            className="mt-3 mb-3"
            src={Logo}
            alt=""
            width={70}
            height={134}
            onClick={() => {
              reset();
            }}
          />
        </div>
        <div className="bg-white align-items-center rounded-2 p-2">
          <div
            style={{ maxWidth: "400px", margin: "0 auto" }}
            dangerouslySetInnerHTML={{ __html: recipe }}
          />
          <div
            style={{ width: "100%", textAlign: "center" }}
          >{`Created By ${name}`}</div>
        </div>
      </div>
      <footer>
        <div className="mt-5 text-center">
          <div className="d-flex justify-content-center ">
            <button className="btn border-0" onClick={reset}>
              <img src={Refresh} alt="" />
            </button>
            {/* <button className="btn border-0" onClick={handleShare}>
              <img src={Share} alt="" />
            </button> */}
          </div>
        </div>
      </footer>
    </>
  );
};

export default ShareRecipe;
